import axios from 'axios';
import { API_URL, SETTINGS } from '../variables';
import { getHeaders } from './login';
import { mapProduct } from './mapApiInterface';

//  GET

export function getCart(cartId) {
  return axios
    .get(`${API_URL}/cart/${cartId}`, getHeaders())
    .then(({ data: { cart } }) => {
      const { cart_items } = cart;
      const mappingProducts = cart_items?.map((product) => mapProduct(product));
      return { ...cart, cart_items: mappingProducts };
    });
}

export function getUser() {
  return axios
    .get(`${API_URL}/user`, getHeaders())
    .then(({ data: { user } }) => user);
}

export function getPreviousOrders(customerId) {
  return axios
    .get(`${API_URL}/orders?customerId=${customerId}`, getHeaders())
    .then(({ data: { orders } }) => orders);
}

export function getReceipts(customerId) {
  return axios
    .get(`${API_URL}/orders/account?customerId=${customerId}`, getHeaders())
    .then(({ data: { account } }) => account);
}

export function getReceiptInvoice(docId, docType) {
  return axios
    .get(`${API_URL}/orders/document/${docType}/${docId}`, {
      responseType: 'blob',
      ...getHeaders()
    })
    .then(({ data }) => data);
}

export function getProductUserHistory(customerId) {
  return axios
    .get(`${API_URL}/user/product-history/${customerId}`, getHeaders())
    .then(({ data: { productHistory } }) =>
      productHistory.map((product) => mapProduct(product))
    );
}

export function getUsers() {
  return axios
    .get(`${API_URL}/user/users`, getHeaders())
    .then(({ data: { users } }) => users);
}

// POST

export function setCustomer(customerId) {
  return axios
    .post(`${API_URL}/user/customers/select-customer`, customerId, getHeaders())
    .then(({ data: { message } }) => message);
}

//  BEGIN users
export function addUser(newUserData) {
  return axios
    .post(`${API_URL}/user/create/user`, newUserData, getHeaders())
    .then(({ data }) => data);
}

export function updateUser(newUserData) {
  return axios
    .post(`${API_URL}/user/update/user`, newUserData, getHeaders())
    .then(({ data: user }) => user);
}

export function deleteUser(email) {
  return axios
    .post(`${API_URL}/user/delete/user`, { email: email }, getHeaders())
    .then(({ data }) => data);
}
//  END users

export function addAddress(newDeliveryAddress) {
  return axios
    .post(`${API_URL}/user/create/address`, newDeliveryAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

export function updateAddress(updatedAddress) {
  return axios
    .post(`${API_URL}/user/update/address`, updatedAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

export function deleteAddress(addressId) {
  return axios
    .post(`${API_URL}/user/delete/address`, { id: addressId }, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

export function addProductToUserHistory(reference, customerId) {
  return axios
    .post(
      `${API_URL}/user/product-history`,
      { reference, userId: customerId },
      getHeaders()
    )
    .then(({ data }) => data);
}

export function approveOrder(orderId) {
  return axios
    .post(`${API_URL}/orders/update`, { orderId }, getHeaders())
    .then(({ data }) => data);
}

export function duplicateOrder(customerId, orderId) {
  return axios
    .post(`${API_URL}/orders/duplicate`, { customerId, orderId }, getHeaders())
    .then(({ data }) => data);
}

//  SHOPPING LIST

export function getShoppingLists() {
  return axios
    .get(`${API_URL}/shopping-lists`, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product))
      }))
    );
}

export function addShoppingList(name) {
  return axios
    .post(`${API_URL}/shopping-lists/create`, { name }, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product))
      }))
    );
}

export function addProductToShoppingList(listId, product_id) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/add`,
      { product_id },
      getHeaders()
    )
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) =>
          mapProduct({
            ...product,
            quantity: {
              [SETTINGS.defaults.quantityType]: 1
            }
          })
        )
      }))
    );
}

export function deleteShoppingList(id) {
  return axios
    .post(`${API_URL}/shopping-lists/${id}/delete`, {}, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product))
      }))
    );
}

export function deleteProductFromShoppingList(listId, productId) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/remove`,
      { product_id: productId },
      getHeaders()
    )
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product))
      }))
    );
}
