import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../api/products';
import { addProductToUserHistory } from '../api/user';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';
import ProductDetails from '../components/ProductPage/ProductDetails/ProductDetails';
import ProductImages from '../components/ProductPage/ProductImages';
import ProductRelated from '../components/ProductPage/ProductRelated';
import ProductTabs from '../components/ProductPage/ProductTabs/ProductTabs';
import { generateBreadcrumbsPath } from '../helpers';
import { MainContainer } from '../styles';
import { PAGES_SLUGS, SETTINGS } from '../variables';

const ProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collectionSlug, categorySlug, subcategorySlug, productSlug } =
    useParams();
  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const { categories } = useSelector((state) => state.categories);
  const user = useSelector((state) => state.user);
  const { role, selectedClient, id } = user;
  const customerId = selectedClient?.id || id;

  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;

  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [breadcrumbsPath, setBreadcrumbsPath] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      getProduct(productSlug)
        .then((newProduct) => {
          setProduct(newProduct);

          if (!SETTINGS.defaults.productDetail.categoriesHierarchySlug) {
            setBreadcrumbsPath(
              generateBreadcrumbsPath(categories, [
                newProduct.collection,
                newProduct.category
              ])
            );
          }

          if (userIsLogged && !!customerId && role !== 'seller') {
            addProductToUserHistory(newProduct.reference, customerId).catch(
              ({ response: { status } }) => {
                if (status === 401)
                  navigate(PAGES_SLUGS.login, { replace: true });
              }
            );
          }

          setIsLoading(false);
        })
        .catch(({ response: { status } }) => {
          if (status === 400 || status > 401)
            navigate(PAGES_SLUGS.notFound, { replace: true });
        });
    }, 250);

    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSlug, selectedClient?.id]);

  if (SETTINGS.defaults.productDetail.categoriesHierarchySlug) {
    useEffect(() => {
      setBreadcrumbsPath(
        generateBreadcrumbsPath(categories, [
          collectionSlug,
          categorySlug,
          subcategorySlug
        ])
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionSlug, categorySlug, subcategorySlug]);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!product) {
    return null;
  }

  const availableParams = product.params.filter(
    (param) => param.value && param.value !== 'undefined'
  );

  return (
    <MainContainer>
      <Container>
        <Grid container>
          {SETTINGS.defaults.productDetail.breadcrumbs && (
            <Grid
              xs={12}
              sx={{
                marginTop: -2,
                marginBottom: 2
              }}
            >
              <Breadcrumbs
                pathArray={[
                  ...breadcrumbsPath,
                  {
                    title: product.title,
                    slug: product.slug
                  }
                ]}
              />
            </Grid>
          )}
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: MOBILE_DEVICES && 'column'
              }}
              gap={{ xs: 3, md: 6 }}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  margin: { xs: '0 auto', md: '0' }
                }}
              >
                <ProductImages images={product?.images} />
              </Box>

              <ProductDetails
                product={product}
                userIsLogged={userIsLogged}
                customerId={customerId}
              />
            </Box>

            {SETTINGS.defaults.productDetail.tabs.visibility && (
              <ProductTabs
                video={product.video}
                technicalDetails={availableParams}
                technicalSheets={product.technicalSheet}
              />
            )}

            {SETTINGS.defaults.productDetail.relatedProducts &&
              !!product.related.length && (
                <ProductRelated related={product.related} />
              )}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductPage;
