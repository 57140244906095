import { Box, Button, Container, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsDownload } from 'react-icons/bs';
import { MainContainer } from '../styles';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.3rem',
  marginTop: theme.spacing(3)
}));

export const DownloadButton = styled(Button)(() => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline'
  }
}));

const PressKit = ({ props }) => {
  const { t } = useTranslation();
  const [pageContent] = useState(props);
  const { identityManual, catalogs, socialNetworks } = pageContent;
  const sampleImage = `${process.env.PUBLIC_URL}/sample-image.png`;
  const onErrorImage = (image) => {
    image.onerror = null; // prevents looping
    image.target.src = sampleImage;
  };

  return (
    <MainContainer>
      <Container>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box
              sx={{
                textAlign: 'center'
              }}
            >
              <Typography variant='h1'>{t('pressKit.title')}</Typography>
              <Typography marginTop={2}>{t('aboutUs.text')}</Typography>
            </Box>
          </Grid>
          <Grid container marginY={{ xs: 3, md: 6 }}>
            <Grid xs={12} sm={6} md={4} marginBottom={3}>
              <Box
                component='img'
                src={identityManual.image || sampleImage}
                onError={onErrorImage}
                alt={t('stockslist.title')}
                sx={{ maxWidth: '100%' }}
              />
              <Title variant='h2'>{t('pressKit.identityManualTitle')}</Title>
              <Box
                sx={{
                  display: 'grid',
                  justifyItems: ' flex-start',
                  gap: 2,
                  marginTop: 2.5
                }}
              >
                {identityManual.items.map((item, index) => (
                  <DownloadButton
                    key={`identityManual-${index}`}
                    component='a'
                    target='_blank'
                    href={item.url}
                    download
                    title={item.title}
                    endIcon={<BsDownload size={16} />}
                  >
                    {item.title}
                  </DownloadButton>
                ))}
              </Box>
            </Grid>
            <Grid xs={12} sm={6} md={4} marginBottom={3}>
              <Box
                component='img'
                src={catalogs.image || sampleImage}
                onError={onErrorImage}
                alt={t('stockslist.title')}
                sx={{ maxWidth: '100%' }}
              />
              <Title variant='h2'>{t('pressKit.catalogsTitle')}</Title>
              <Box
                sx={{
                  display: 'grid',
                  justifyItems: ' flex-start',
                  gap: 2,
                  marginTop: 2.5
                }}
              >
                {catalogs.items.map((item, index) => (
                  <DownloadButton
                    key={`identityManual-${index}`}
                    component='a'
                    target='_blank'
                    href={item.url}
                    download
                    title={item.title}
                    endIcon={<BsDownload size={16} />}
                  >
                    {item.title}
                  </DownloadButton>
                ))}
              </Box>
            </Grid>
            <Grid xs={12} sm={6} md={4} marginBottom={3}>
              <Box
                component='img'
                src={socialNetworks.image || sampleImage}
                onError={onErrorImage}
                alt={t('stockslist.title')}
                sx={{ maxWidth: '100%' }}
              />
              <Title variant='h2'>{t('pressKit.socialNetworksTitle')}</Title>
              <Box
                sx={{
                  display: 'grid',
                  justifyItems: ' flex-start',
                  gap: 2,
                  marginTop: 2.5
                }}
              >
                {socialNetworks.items.map((item, index) => (
                  <DownloadButton
                    key={`identityManual-${index}`}
                    component='a'
                    target='_blank'
                    href={item.url}
                    download
                    title={item.title}
                    endIcon={<BsDownload size={16} />}
                  >
                    {item.title}
                  </DownloadButton>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default PressKit;
