import { Box, Typography, styled, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { formatCurrency } from '../../constants/utils';
import { THEME_COLORS, FONT_STYLE, SETTINGS } from '../../variables';

const StyledOrderSummaryBox = styled(Box)(({ theme, isdiscount, istotal }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: istotal === 'true' ? theme.spacing(2) : theme.spacing(1.25),
  color: isdiscount && THEME_COLORS.red,
  '&:last-of-type': {
    p: {
      fontSize: istotal === 'true' && '1.2rem',
      fontWeight: istotal === 'true' && FONT_STYLE.bold
    }
  }
}));

const StyledOrderSummaryText = styled(Typography)(() => ({
  fontSize: '0.85rem',
  fontWeight: FONT_STYLE.bold
}));

const CheckoutResume = ({
  cart: { products, discount, discounts, taxes, subtotal },
  total,
  deliveryCost,
  isLimitedCustomer
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;

  const totalUnits = products.reduce(
    (acc, item) => acc + item.quantity.unit,
    0
  );
  const _totalBoxes = products.reduce(
    (acc, item) => acc + item.quantity.box,
    0
  );
  const pvpPriceItemsSum = products.reduce(
    (acc, item) =>
      Math.fround(
        acc +
          //  remove IVA percentage from PVP PRICES
          (item.pvpPrice - item.pvpPrice * (item.tax / 100)) *
            (item.quantity.unit + item.quantity.box)
      ),

    0
  );
  const customerGains = Math.abs(pvpPriceItemsSum - subtotal);

  const orderSummary = [
    {
      id: 0,
      text: t('checkout.summaryCard.units'),
      value: totalUnits,
      items: []
    },
    // {
    //   id: 1,
    //   text: t('checkout.summaryCard.boxes'),
    //   value: totalBoxes,
    //   items: []
    // },
    {
      id: 2,
      text: t('checkout.summaryCard.references'),
      value: products.length,
      items: []
    },
    {
      id: 3,
      text: t('checkout.summaryCard.subtotal'),
      value: formatCurrency(subtotal),
      items: []
    },
    ...taxes.map((tax) => ({
      id: 4,
      text: t('checkout.summaryCard.iva', { amount: tax.iva }),
      value: formatCurrency(tax.total),
      items: []
    })),
    {
      id: 5,
      text: t('checkout.summaryCard.shipping'),
      value:
        deliveryCost !== -1 ? formatCurrency(deliveryCost) : t('app.onRequest'), //  when 'shipping price' is '-1' means shipping price is 'on request'
      items: []
    },
    {
      id: 6,
      text: t('checkout.summaryCard.discounts'),
      value: !!discounts.length ? '' : formatCurrency(discount),
      items: discounts
    },
    {
      id: 7,
      text: t('checkout.summaryCard.total'),
      value: formatCurrency(total),
      items: []
    }
  ].filter(
    (summaryItem) =>
      //  IF there is no 'discounts' hide it
      !(summaryItem.id === 6 && discount === 0) &&
      //  IF 'isLimitedCustomer' hide some items
      !(
        isLimitedCustomer &&
        !(summaryItem.id === 0 || summaryItem.id === 1 || summaryItem.id === 2)
      )
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: { sm: '50%', md: '35%', lg: '30%' },
        position: { md: 'sticky' },
        top: { md: `${headerHeight + 20}px` },
        marginBottom: 2
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 3,
          border: `1px solid ${THEME_COLORS.darkGray}`,
          borderRadius: theme.shape.borderRadius / 10
        }}
      >
        <Typography
          variant='h3'
          sx={{
            textAlign: 'center',
            marginBottom: 3
          }}
        >
          {t('checkout.summaryCard.title')}
        </Typography>

        {orderSummary.map((summaryItem) => (
          <StyledOrderSummaryBox
            key={`orderSummaryItem-${summaryItem.text}`}
            isdiscount={summaryItem.id === 6 ? 'true' : undefined}
            istotal={summaryItem.id === 7 ? 'true' : undefined}
          >
            <StyledOrderSummaryText>{summaryItem?.text}</StyledOrderSummaryText>
            <StyledOrderSummaryText>
              {summaryItem?.value}
            </StyledOrderSummaryText>
            {/* all discounts available */}
            {!!summaryItem?.items.length &&
              summaryItem?.items.map((item, index) => (
                <Box
                  key={`orderSummaryItem-discount-${index}`}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 1,
                    marginLeft: 2
                  }}
                >
                  <StyledOrderSummaryText>{`${item.title} ${
                    !!item?.percentage ? `(${item?.percentage})` : ''
                  }`}</StyledOrderSummaryText>
                  <StyledOrderSummaryText>{item.value}</StyledOrderSummaryText>
                </Box>
              ))}
          </StyledOrderSummaryBox>
        ))}
      </Box>

      {SETTINGS.defaults.cart.checkout.customerGains &&
        !!customerGains &&
        !isLimitedCustomer && (
          <Typography
            variant='small'
            sx={{
              fontWeight: FONT_STYLE.semibold,
              color: THEME_COLORS.red,
              textAlign: 'center'
            }}
          >
            <Trans
              i18nKey='checkout.customerGains'
              values={{
                amount: `<strong>${formatCurrency(customerGains)}</strong>`
              }}
            />
          </Typography>
        )}

      <Typography textAlign='center'>
        {t('checkout.orderSubjectToConfirmation')}
      </Typography>
    </Box>
  );
};

export default CheckoutResume;
