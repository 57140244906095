import {
  Box,
  Button,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { kebabCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiEdit2, FiPercent, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatCurrency, priceCalculations } from '../../../constants/utils';
import { BadgeDiscount, IconButton } from '../../../styles';
import {
  FONT_STYLE,
  PAGES_SLUGS,
  SETTINGS,
  THEME_COLORS
} from '../../../variables';
import Quantity from '../../Quantity/Quantity';
import PromotionalCode from '../PromotionalCode';
import TaxProductButton from '../TaxProductButton';

const SummaryTable = ({
  isLoading,
  cartItems,
  cartTotalDiscount,
  cartSubtotal,
  role,
  accountIsBlocked,
  updateCart,
  setProductDiscountUpdate,
  setDiscountModalIsOpen
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const DESKTOP_DEVICES = useMediaQuery(theme.breakpoints.up('sm'));
  const translations = useSelector((state) => state.translations);
  const [cookie] = useCookies();

  const hidePriceCell = true;
  const showDiscountOption = role === 'seller';
  const isLimitedCustomer = role === 'limitedcustomer';
  const hasTaxQuantities = cartItems.some((variant) => {
    const sizeParam = variant?.params?.find(
      (param) => param?.id === 'size'
    )?.value;
    return !!sizeParam &&
      ((Number(sizeParam) >= 47 && Number(sizeParam) <= 52) ||
        sizeParam === '4XL' ||
        sizeParam === '5XL' ||
        sizeParam === '6XL')
      ? true
      : false;
  });
  let cartReferencesGroup = [];

  if (SETTINGS.defaults.uniqueStock) {
    cartReferencesGroup = cartItems.reduce(
      (acc, { reference, stock, quantity, itemsPerBox }) => {
        !!acc[reference]
          ? (acc[reference].stockAvailable =
              acc[reference].stockAvailable -
              quantity.unit -
              quantity.box * itemsPerBox)
          : (acc[reference] = {
              stockAvailable: stock - quantity.unit - quantity.box * itemsPerBox
            });
        return acc;
      },
      {}
    );
  }

  const [iconButton, setIconButton] = useState(null);
  const [updatedProductQuantity, setUpdatedProductQuantity] = useState([]);

  useEffect(() => {
    if (updatedProductQuantity.length === 0) return;

    if (SETTINGS.defaults.groupedVariants) {
      let cartQuantitiesUpdated = cartItems.reduce(
        (acc, product) => [
          ...acc,
          ...product.variants.map((variant) => ({
            ...variant,
            //  update product quantities of product changed
            quantity: (
              SETTINGS.defaults.productIdentifier === 'reference'
                ? variant.reference === updatedProductQuantity[0].reference
                : !!variant.eanUn
                  ? variant.eanUn === updatedProductQuantity[0].eanUn
                  : variant.eanCx === updatedProductQuantity[0].eanCx
            )
              ? updatedProductQuantity[0].quantity
              : variant.quantity
          }))
        ],
        []
      );

      const variantGroupHasAllQuantitiesZero = cartQuantitiesUpdated
        .filter((a) => a.parentId === updatedProductQuantity[0].parentId)
        .every((b) => b.quantity.unit === 0);

      if (variantGroupHasAllQuantitiesZero) {
        cartQuantitiesUpdated = cartQuantitiesUpdated.filter(
          (a) => a.parentId !== updatedProductQuantity[0].parentId
        );
      }

      updateCart(cartQuantitiesUpdated);
    } else {
      updateCart(
        cartItems.map((product) => ({
          ...product,
          quantity: (
            SETTINGS.defaults.productIdentifier === 'reference'
              ? product.reference === updatedProductQuantity[0].reference
              : !!product.eanUn
                ? product.eanUn === updatedProductQuantity[0].eanUn
                : product.eanCx === updatedProductQuantity[0].eanCx
          )
            ? updatedProductQuantity[0].quantity
            : product.quantity
        }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity]);

  const deleteProduct = (productIdentifier) => {
    if (SETTINGS.defaults.groupedVariants) {
      return updateCart(
        cartItems
          .filter(
            (product) =>
              (product.parentId || product.reference) !== productIdentifier
          )
          .reduce((acc, product) => [...acc, ...product.variants], [])
      );
    } else {
      return updateCart(
        cartItems.filter(
          (product) =>
            (product.parentId || product.reference) !== productIdentifier
        )
      );
    }
  };

  if (SETTINGS.defaults.groupedVariants) {
    cartItems = cartItems.reduce((acc, variant) => {
      const uniqueParentId = !acc.some((a) => a.parentId === variant.parentId);
      if (uniqueParentId) {
        acc.push({ ...variant, variants: [variant] });
      } else {
        const parentIdIndex = acc.findIndex(
          (a) => a.parentId === variant.parentId
        );
        acc[parentIdIndex].variants.push(variant);
      }
      return acc;
    }, []);

    //  sort pattern
    const sortBy = [
      'XS',
      'S',
      'M',
      'L',
      'XL',
      'XXL',
      '3XL',
      '4XL',
      '5XL',
      '6XL'
    ];
    //  sort all variants using custom pattern (string sizes) OR subtraction (number sizes)
    cartItems = cartItems.map((variant) => ({
      ...variant,
      variants: variant.variants.sort((a, b) => {
        const paramsA = a.params?.find((param) => param?.id === 'size')?.value;
        const paramsB = b.params?.find((param) => param?.id === 'size')?.value;
        //  check IF string contains only numbers
        const isnum = /^\d+$/.test(paramsA);
        return isnum
          ? Number(paramsA) - Number(paramsB)
          : sortBy.indexOf(paramsA) - sortBy.indexOf(paramsB);
      })
    }));
  }

  const uniqueVariationFields = cartItems
    .reduce((acc, product) => {
      product.variationFields.length &&
        product.variationFields.map(
          (variation) =>
            !acc.some((a) => a === variation) && acc.push(variation)
        );
      return acc;
    }, [])
    .sort((a, b) => a.localeCompare(b));

  const variationLabels = uniqueVariationFields.map((field) => {
    const translatedField = translations.find((a) => a.id === field);
    return translatedField?.[cookie.language] || field || '-';
  });

  const setVariationValues = (variationParams) => {
    return uniqueVariationFields.map((variationName) => {
      const variationValue = variationParams.find(
        (param) => kebabCase(param.id) === kebabCase(variationName)
      )?.value;
      const translatedVariationValues = translations.find(
        (a) => a.id === variationValue
      );
      return (
        translatedVariationValues?.[cookie.language] || variationValue || '-'
      );
    });
  };

  const TaxQuantitiesLegend = () => {
    return (
      !!hasTaxQuantities && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            marginBottom: 1.5
          }}
        >
          <Box
            sx={{
              width: '12px',
              height: '12px',
              minWidth: '12px',
              backgroundColor: 'black'
            }}
          />
          <Typography variant='xsmall'>
            {t('product.taxProductLegend')}
          </Typography>
        </Box>
      )
    );
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          position: 'relative',
          marginY: 1
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: { xs: '175px', md: 'initial' } }}>
                {t('tables.reference')}
              </TableCell>
              {/* variation fields */}
              {variationLabels.length &&
                variationLabels.map((label, index) => (
                  <TableCell
                    key={`variationFieldLabel-${index}`}
                    sx={{ minWidth: { xs: '125px', md: 'initial' } }}
                  >
                    {label}
                  </TableCell>
                ))}
              <TableCell sx={{ minWidth: { xs: '140px', md: 'initial' } }}>
                {t('tables.selectQuantity')}
              </TableCell>
              <TableCell sx={{ minWidth: { xs: '125px', md: 'initial' } }}>
                {t('tables.totalUnits')}
              </TableCell>
              {!isLimitedCustomer && (
                <>
                  {!hidePriceCell && (
                    <TableCell
                      sx={{
                        minWidth: { xs: '150px', md: 'initial' }
                      }}
                    >
                      {t('tables.price')}*
                    </TableCell>
                  )}
                  {showDiscountOption && (
                    <TableCell
                      sx={{ minWidth: { xs: '240px', md: 'initial' } }}
                    >
                      {t('tables.sellerDiscount')}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      minWidth: { xs: '100px', md: 'initial' }
                    }}
                  >
                    {t('tables.subtotal')}*
                  </TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(cartItems) &&
              cartItems.map((product, index) => {
                const {
                  reference,
                  parentId,
                  parentReference,
                  title,
                  slug,
                  price,
                  salePrice,
                  onSale,
                  onSaleBox,
                  boxPrice,
                  boxSalePrice,
                  saleAmount,
                  quantity,
                  discount,
                  stockPerBox,
                  stock,
                  params,
                  variants,
                  taxProduct
                } = product;
                const isUnitQuantity = Boolean(quantity.unit !== 0);
                const pricePerUnit = isUnitQuantity //  unit price
                  ? onSale
                    ? salePrice
                    : price
                  : onSaleBox //  box price
                    ? boxSalePrice
                    : boxPrice;
                const productOnSale = onSale || onSaleBox;
                const productPrice = isUnitQuantity ? price : boxPrice;
                const productSalePrice = isUnitQuantity
                  ? salePrice
                  : boxSalePrice;
                const hasDiscount = discount > 0;
                const totalUnits = variants.reduce(
                  (acc, variant) => acc + variant.quantity.unit,
                  0
                );
                // const quantityAmount = quantity.unit + quantity.box;
                const subtotal = pricePerUnit * totalUnits;
                const subtotalWithDiscount = priceCalculations(
                  pricePerUnit * totalUnits -
                    (pricePerUnit * totalUnits * discount) / 100
                );
                const _outOfStock = SETTINGS.defaults.uniqueStock
                  ? Boolean(stock <= 0)
                  : isUnitQuantity
                    ? Boolean(stock <= 0)
                    : Boolean(stockPerBox <= 0);
                const variationValues = setVariationValues(params);

                return (
                  <TableRow key={`cartItem-${parentId}-${index}`}>
                    <TableCell>
                      <Button
                        variant='link'
                        component={!taxProduct ? Link : null}
                        to={slug}
                        title={title}
                        sx={{
                          fontSize: 'inherit',
                          cursor: taxProduct ? 'default' : 'inital',
                          '&:hover': {
                            pointerEvents: taxProduct ? 'none' : 'initial',
                            textDecoration: taxProduct ? 'underline' : 'none'
                          }
                        }}
                      >
                        {parentReference}
                      </Button>
                      {taxProduct && <TaxProductButton t={t} />}
                    </TableCell>
                    {/* variation fields */}
                    {variationValues.length &&
                      variationValues.map((value, index) => (
                        <TableCell key={`variationFieldValue-${index}`}>
                          {value}
                        </TableCell>
                      ))}
                    {/* quantity */}
                    <TableCell>
                      {!taxProduct && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 0.25
                          }}
                        >
                          {variants.map((variant, index) => (
                            <Quantity
                              key={`sizeVariant-${index}`}
                              product={variant}
                              setUpdatedProductQuantity={
                                setUpdatedProductQuantity
                              }
                              isProductVariant
                              variant='size'
                              label={
                                variant.params.find((a) => a.id === 'size')
                                  ?.value || '-'
                              }
                              stockAvailable={
                                SETTINGS.defaults.uniqueStock &&
                                cartReferencesGroup[reference].stockAvailable
                              }
                              isLoading={isLoading}
                            />
                          ))}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>{totalUnits}</TableCell>
                    {!isLimitedCustomer && (
                      <>
                        {/* your price */}
                        {!hidePriceCell && (
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 1
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 'inherit',
                                  textDecoration:
                                    productOnSale && 'line-through'
                                }}
                              >
                                {formatCurrency(productPrice)}
                              </Typography>

                              {productOnSale && (
                                <>
                                  <Typography
                                    variant='small'
                                    sx={{
                                      fontSize: 'inherit',
                                      fontWeight: FONT_STYLE.bold,
                                      color: THEME_COLORS.primary
                                    }}
                                  >
                                    {formatCurrency(productSalePrice)}
                                  </Typography>
                                  <BadgeDiscount>{`-${saleAmount}%`}</BadgeDiscount>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        )}

                        {/* seller discount */}
                        {showDiscountOption && (
                          <TableCell>
                            <IconButton
                              title={
                                hasDiscount
                                  ? t('cart.editSellerDiscount')
                                  : t('cart.applySellerDiscount')
                              }
                              sx={{
                                gap: 1,
                                color: hasDiscount && THEME_COLORS.red,
                                fontWeight: hasDiscount && FONT_STYLE.bold
                              }}
                              onMouseEnter={() => {
                                return hasDiscount && setIconButton(index);
                              }}
                              onMouseLeave={() => {
                                return hasDiscount && setIconButton(null);
                              }}
                              onClick={() => {
                                setProductDiscountUpdate(product);
                                setDiscountModalIsOpen(true);
                              }}
                            >
                              <Icon
                                sx={{
                                  backgroundColor: THEME_COLORS.gray,
                                  borderRadius: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                {hasDiscount ? (
                                  iconButton === index ? (
                                    <FiEdit2 size={12} />
                                  ) : (
                                    <FiCheck size={12} />
                                  )
                                ) : (
                                  <FiPercent size={12} />
                                )}
                              </Icon>
                              {hasDiscount
                                ? t('cart.sellerDiscountAmout', {
                                    amount: discount
                                  })
                                : t('cart.applySellerDiscount')}
                            </IconButton>
                          </TableCell>
                        )}

                        {/* subtotal */}
                        <TableCell>
                          <Box
                            sx={{
                              flex: 1,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 'inherit',
                                textDecoration: hasDiscount && 'line-through'
                              }}
                            >
                              {formatCurrency(subtotal)}
                            </Typography>

                            {hasDiscount && (
                              <Typography
                                sx={{
                                  fontSize: 'inherit',
                                  color: THEME_COLORS.red,
                                  fontWeight: FONT_STYLE.bold
                                }}
                              >
                                {formatCurrency(subtotalWithDiscount)}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                      </>
                    )}

                    <TableCell>
                      {!taxProduct && (
                        <IconButton
                          onClick={() => deleteProduct(parentId || reference)}
                          title={t('tables.actions.remove')}
                          disabled={isLoading}
                        >
                          <FiTrash2 size={18} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          marginTop: 2,
          display: { xs: 'grid', sm: 'flex' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'flex-start',
          gap: { xs: 3, sm: 0 }
        }}
      >
        <Box
          sx={{
            display: 'grid',
            justifyItems: { xs: 'center', sm: 'start' },
            gap: { xs: 2, sm: 3 }
          }}
        >
          {!DESKTOP_DEVICES && (
            <Box>
              <TaxQuantitiesLegend />
              <Typography variant='xsmall'>
                {t('product.priceWithoutVat')}
              </Typography>
            </Box>
          )}

          <Button
            variant='outlined'
            size='small'
            title={t('cart.clearCartButton')}
            disabled={isLoading}
            sx={{
              borderColor: THEME_COLORS.red,
              color: THEME_COLORS.red,
              '&:hover': {
                borderColor: THEME_COLORS.red,
                backgroundColor: THEME_COLORS.red
              }
            }}
            onClick={() => updateCart([])}
          >
            {t('cart.clearCartButton')}
          </Button>
          {SETTINGS.functionalities.promotionalCode && <PromotionalCode />}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: 3
          }}
        >
          {!isLimitedCustomer && (
            <>
              {DESKTOP_DEVICES && (
                <Box>
                  <TaxQuantitiesLegend />
                  <Typography
                    variant='xsmall'
                    sx={{
                      textAlign: 'right'
                    }}
                  >
                    {t('product.priceWithoutVat')}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: 'grid',
                  justifyContent: { sm: 'end' },
                  gap: 1
                }}
              >
                {!!cartTotalDiscount && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      gap: { xs: 0.5, sm: 4 },
                      color: THEME_COLORS.red,
                      fontWeight: FONT_STYLE.bold
                    }}
                  >
                    <Typography>{t('cart.orderDiscounts')}</Typography>
                    <Typography>{formatCurrency(cartTotalDiscount)}</Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    gap: { sm: 4 },
                    fontWeight: FONT_STYLE.bold
                  }}
                >
                  <Typography>{t('cart.orderTotal')}</Typography>
                  <Typography
                    sx={{
                      fontSize: '1.6rem',
                      color: THEME_COLORS.primary
                    }}
                  >
                    {formatCurrency(cartSubtotal)}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              flexWrap: 'wrap',
              gap: 2
            }}
          >
            <Button
              variant='outlined'
              component={Link}
              to='/'
              title={t('cart.backToStoreButton')}
            >
              {t('cart.backToStoreButton')}
            </Button>

            <Button
              variant='contained'
              component={!accountIsBlocked ? Link : null}
              to={!accountIsBlocked ? PAGES_SLUGS.checkout : null}
              title={t('cart.goToCheckoutButton')}
              disabled={accountIsBlocked}
              sx={{
                minWidth: '200px'
              }}
            >
              {t('cart.goToCheckoutButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(SummaryTable);
