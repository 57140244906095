export const API_URL = `${process.env.REACT_APP_API_URL}/store`;
export const PLACEHOLDER_IMAGE = `${
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_WEBSITE_URL
    : process.env.PUBLIC_URL
}/default_image.png`;

//  CUSTOM COLORS
export const THEME_COLORS = {
  primary: '#000',
  lightPrimary: '#353535',
  secundary: 'transparent',
  gray: '#DDDDDD',
  lightGray: '#f0f0f0',
  darkGray: '#666666',
  red: '#9A031E',
  green: '#008148',
  lightGreen: '#4FD290',
  yellow: '#FFC01D',
  orange: '#ffa726'
};

//  FONT STYLES
export const FONT_STYLE = {
  thin: 100,
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700
};

//  STYLES DEFAULTS
export const TRANSITION_DURATION = 200;

// info
export const COMPANY_NAME = 'Baccus';
export const COMPANY_INFO = {
  name: 'Baccus',
  address: {
    street: 'Avenida Associação Comercial e Industrial de Gondomar, nº 330',
    zipCode: '4510-706 Fânzeres',
    city: 'Gondomar',
    country: 'Portugal'
  },
  phone: {
    label: '(+351) 226 102 617',
    value: '+351226102617'
  },
  mobile: {
    label: '',
    value: ''
  },
  fax: {
    label: '',
    value: ''
  },
  whatsappPhoneNumber: '',
  email: ['baccus@dcd.pt'],
  social: {
    facebook: 'https://www.facebook.com/www.baccus.pt/',
    linkedin: '',
    instagram: 'https://www.instagram.com/baccuscamisas/'
  },
  nif: '501940030',
  mapsLink: 'https://maps.app.goo.gl/8hRoY8MFTyXmPXcL9'
};

export const SETTINGS = {
  development: {
    sampleData: {
      homepage: false,
      productList: false,
      productDetail: false,
      collections: false
    }
  },
  functionalities: {
    myAccount: true,
    shoppingList: true,
    accountReceipts: true,
    notificationsBar: false,
    hidePrices: false,
    variantsModal: false,
    variantsTable: true,
    translations: false,
    manageUsers: false,
    productListViews: false,
    onlyUnitBoxProducts: false, //  happens when the variants are only 'unit' & 'box' products
    promotionalCode: true,
    whatsappWidget: false,
    twoFactorAuthentication: false,
    blockedAccountModal: true
  },
  defaults: {
    language: 'pt',
    languageOptions: ['pt'], //  available options » en/es
    quantityType: 'unit', //  unit/box
    uniqueStock: false, //  means 'stock' key is general stock (units & boxes)
    orderWithoutStock: true, //  products without stock can be added to cart
    countries: [],
    productIdentifier: 'reference', //  reference/ean
    groupedVariants: true,
    translateCategories: false,
    homepage: {
      mainBanner: true,
      highlightedProducts: true,
      highlightedBrands: false,
      highlightedCategories: false,
      secundaryBanners: false,
      threeHighlightedBanners: true,
      dealsSection: true
    },
    footer: {
      socialNetworks: true,
      informations: true,
      aboutUs: true,
      customerSupport: false,
      contactUs: true
    },
    productList: {
      itemsPerPage: 24,
      view: 'grid', //  grid/list
      breadcrumbs: true,
      filters: true,
      categoriesList: false,
      sortBy: 'name:asc',
      sortOptions: [
        {
          id: 'name:asc',
          text: 'productList.sortOptions.nameAsc'
        },
        {
          id: 'name:desc',
          text: 'productList.sortOptions.nameDec'
        },
        {
          id: 'price:asc',
          text: 'productList.sortOptions.priceAsc'
        },
        {
          id: 'price:desc',
          text: 'productList.sortOptions.priceDesc'
        }
        // {
        //   id: 'date:desc',
        //   text: 'Mais Recente',
        // },
        // {
        //   id: 'date:asc',
        //   text: 'Mais Antigo',
        // },
      ]
    },
    productDetail: {
      categoriesHierarchySlug: true,
      breadcrumbs: true,
      pvpPrice: true,
      revPrice: true,
      customerDiscount: false,
      rightSideResume: true,
      brand: false,
      taxes: false,
      eanUn: false,
      eanCx: false,
      description: true,
      itemsPerBox: false,
      estimatedDelivery: false,
      sizeGuideModal: true,
      budgetRequestButton: false,
      stockAvailability: false,
      badgeDiscount: true,
      relatedProducts: true,
      tabs: {
        visibility: true,
        list: [
          {
            id: 'technicalDetails',
            active: true,
            title: 'product.aditionalInformation'
          },
          {
            id: 'technicalSheets',
            active: false,
            title: 'product.technicalSheets'
          },
          {
            id: 'video',
            active: false,
            title: 'product.video'
          },
          {
            id: 'downloads',
            active: false,
            title: 'product.downloads'
          }
        ]
      }
    },
    productCard: {
      reference: true,
      brand: false,
      pricesFromLabel: false,
      pvpLabel: false,
      pvpPrice: false,
      revLabel: false,
      revPrice: true,
      addToCartButton: false,
      quickAddToCartButton: false,
      discountBadge: true,
      stockAvailability: false,
      outOfStockBadge: true
    },
    productListCard: {
      brand: false,
      unitsPerPackage: true
    },
    cart: {
      resume: {
        totalUnits: true,
        totalBoxes: false
      },
      checkout: {
        payments: true,
        shipping: true,
        observations: true,
        customerGains: false,
        paymentMethods: [
          {
            title: 'checkout.bankTranfer',
            value: 'bankTransfer',
            active: true,
            default: true,
            paymentTime: true
          },
          {
            title: 'checkout.cashOnDelivery',
            value: 'cashOnDelivery',
            active: false,
            default: false
          }
        ],
        shippingMethods: {
          billingAddress: {
            title: 'checkout.billingAddress',
            active: true,
            default: true,
            shippingCosts: true
          },
          deliveryAtStore: {
            title: 'checkout.warehousePickup',
            active: false,
            default: false,
            shippingCosts: false
          },
          deliveryAtAddress: {
            title: 'checkout.unloadingAddress',
            active: true,
            default: false,
            shippingCosts: true
          }
        }
      }
    }
  }
};

//  PAGES SLUGS
export const PAGES_SLUGS = {
  search: '/pesquisa',
  allProducts: '/produtos',
  budgetRequest: '/pedido-orcamento',
  news: '/novidades',
  promotions: '/promocoes',
  contacts: '/contactos',
  cart: '/carrinho',
  checkout: '/checkout',
  orderConfirmation: '/confirmacao-encomenda',
  account: '/conta',
  login: '/login',
  productDetail: '/produto',
  recoverPassword: 'recuperar-palavra-passe',
  createUser: 'registo-utilizador',
  custom: {
    brands: '/marcas',
    stocksList: '/lista-stocks',
    pressKit: 'press-kit'
  },
  categories: {
    shirts: '/camisas',
    cufflinks: '/botoes-de-punho',
    suspenders: '/suspensorios',
    handkerchiefs: '/lencos',
    silkBow: '/lacos',
    echarpes: '/echarpes',
    ties: '/gravatas'
  },
  institutional: {
    aboutUs: 'quem-somos',
    termsAndConditions: 'termos-e-condicoes',
    privacyPolicy: 'politica-de-privacidade',
    alternativeDisputeResolution: 'resolucao-alternativa-de-litigios',
    faqs: 'faqs'
  },
  myAccount: {
    info: `dados-pessoais`,
    accessData: 'dados-acesso',
    receipts: 'conta-corrente',
    shoppingList: 'lista-compras',
    orders: 'encomendas',
    productsHistory: 'visualizacoes',
    users: 'utilizadores',
    addresses: 'moradas'
  },
  notFound: '/pagina-nao-encontrada'
};

export const ACCOUNT_LINKS = {
  customer: [
    {
      title: 'myAccount.personalData.title',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.accessData.title',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
      myAccountMenu: true
    },
    {
      title: 'myAccount.addresses.title',
      url: PAGES_SLUGS.myAccount.addresses,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.users.title',
      url: PAGES_SLUGS.myAccount.users,
      userMenu: SETTINGS.functionalities.manageUsers,
      myAccountMenu: SETTINGS.functionalities.manageUsers
    },
    {
      title: 'myAccount.orders.title',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.receipts.title',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: SETTINGS.functionalities.accountReceipts,
      myAccountMenu: SETTINGS.functionalities.accountReceipts
    },
    {
      title: 'myAccount.shoppingLists.title',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.productHistory.title',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: false,
      myAccountMenu: true
    }
  ],
  seller: [
    {
      title: 'myAccount.personalData.title',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.accessData.title',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
      myAccountMenu: true
    },
    {
      title: 'myAccount.orders.title',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.receipts.title',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: SETTINGS.functionalities.accountReceipts,
      myAccountMenu: SETTINGS.functionalities.accountReceipts
    },
    {
      title: 'myAccount.shoppingLists.title',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: false,
      myAccountMenu: true
    },
    {
      title: 'myAccount.productHistory.title',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: true,
      myAccountMenu: true
    }
  ],
  limitedcustomer: [
    {
      title: 'myAccount.personalData.title',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.accessData.title',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.orders.title',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
      myAccountMenu: true
    },
    {
      title: 'myAccount.shoppingLists.title',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: false,
      myAccountMenu: false
    }
  ]
};

// navbar
export const NAVBAR_LINKS = [
  {
    title: 'pages.categories.shirts',
    url: PAGES_SLUGS.categories.shirts,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  },
  {
    title: 'pages.categories.cufflinks',
    url: PAGES_SLUGS.categories.cufflinks,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  },
  {
    title: 'pages.categories.suspenders',
    url: PAGES_SLUGS.categories.suspenders,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  },
  {
    title: 'pages.categories.handkerchiefs',
    url: PAGES_SLUGS.categories.handkerchiefs,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  },
  {
    title: 'pages.categories.silkBow',
    url: PAGES_SLUGS.categories.silkBow,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  },
  {
    title: 'pages.categories.echarpes',
    url: PAGES_SLUGS.categories.echarpes,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  },
  {
    title: 'pages.categories.ties',
    url: PAGES_SLUGS.categories.ties,
    highlighted: false,
    categoryButton: false,
    menuMobile: true
  }
];

// FOOTER MENUS
export const FOOTER_MENUS = {
  menu1: [
    {
      title: 'pages.aboutUs',
      url: PAGES_SLUGS.institutional.aboutUs,
      internalLink: true,
      target: '',
      loginRequired: false
    },
    {
      title: 'pages.pressKit',
      url: PAGES_SLUGS.custom.pressKit,
      internalLink: true,
      target: '',
      loginRequired: true
    },
    {
      title: 'pages.contacts',
      url: PAGES_SLUGS.contacts,
      internalLink: true,
      target: '',
      loginRequired: false
    }
  ],
  menu2: [
    {
      title: 'pages.termsAndConditions',
      url: PAGES_SLUGS.institutional.termsAndConditions,
      internalLink: true,
      target: '',
      loginRequired: false
    },
    {
      title: 'pages.privacyPolicy',
      url: PAGES_SLUGS.institutional.privacyPolicy,
      internalLink: true,
      target: '',
      loginRequired: false
    },
    {
      title: 'pages.complaintBook',
      url: 'https://www.livroreclamacoes.pt/Inicio/',
      internalLink: false,
      target: '_blank',
      loginRequired: false
    }
  ],
  menu3: [
    {
      title: 'pages.budgetRequest',
      url: PAGES_SLUGS.budgetRequest,
      internalLink: true,
      target: '',
      loginRequired: false
    },
    {
      title: 'pages.stockslist',
      url: PAGES_SLUGS.custom.stocksList,
      internalLink: true,
      target: '',
      loginRequired: false
    }
  ]
};
